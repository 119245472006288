import { LockOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, message } from "antd";
import background from "../assets/backgroundImg.jpg";
import { Login } from "../models/Login";
import { useLoginMutation } from "../redux/api";
import { store } from "../redux/store";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

export default function AdminLogin() {
  const [messageApi, contextHolder] = message.useMessage();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const [form] = Form.useForm();
  const submitHandler = (values: Login) => {
    login(values).then((result: any) => {
      if (!result.error) {
        localStorage.setItem(
          "userEmail",
          JSON.stringify(result.data.value.user.email)
        );
        localStorage.setItem(
          "userName",
          JSON.stringify(result.data.value.user.name)
        );
        dispatch({ type: "login" });
        navigate("/home");
        message.success("User is successfully logged in");
      } else {
        form.resetFields();
        dispatch({ type: "logout" });
        navigate("/");
        message.error("Invalid userid or password");
      }
    });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <>
      {contextHolder}
      <div className="loginBg">
        <Form
          form={form}
          className="login-from"
          layout="vertical"
          onFinish={submitHandler}
          onFinishFailed={onFinishFailed}
        >
          <h3 className="header-align">Welcome to NestTrip</h3>
          <p>Admin Login to NestTrip dashboard</p>
          <Form.Item
            name="userid"
            rules={[{ required: true, message: "Please enter userid!" }]}
            className="form-item"
            label="UserId"
          >
            <Input placeholder="userid" suffix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter password!" }]}
            className="form-item"
            label="Password"
          >
            <Input.Password placeholder="Password" suffix={<LockOutlined />} />
          </Form.Item>
          <Button block type="primary" htmlType="submit">
            Login <LoginOutlined />
          </Button>
        </Form>
      </div>
    </>
  );
}
