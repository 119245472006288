import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { Trip } from "../models/Trip";
import { Vehicle } from "../models/Vehicle";
import { Location } from "../models/Location";
import { User } from "../models/User";
import { Booking } from "../models/Booking";
import { Cancellation } from "../models/Cancellation";
import { Login } from "../models/Login";
import { Book } from "../models/Book";
// https://nesttrip.in/api/
export const myApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl: "/api/" }),
  tagTypes: [
    "Login",
    "Users",
    "Locations",
    "Vehicles",
    "Trips",
    "Bookings",
    "Cancellations",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<object, object>({
      query: (post) => ({
        url: "Admin/login",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Login"],
    }),
    getUsers: builder.query<Array<object>, string>({
      query: () => "User",
      providesTags: ["Users"],
    }),
    getUserById: builder.query<object, string>({
      query: (id) => `User/${id}`,
      providesTags: ["Users"],
    }),
    addUser: builder.mutation<object, object>({
      query: (post) => ({
        url: "User/register",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Users"],
    }),
    updateUser: builder.mutation<void, User>({
      query: ({ id, ...rest }) => ({
        url: `User/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Users"],
    }),
    getLocations: builder.query<Array<object>, string>({
      query: () => "Location",
      providesTags: ["Locations"],
    }),
    getLocationById: builder.query<object, string>({
      query: (id) => `Location/${id}`,
      providesTags: ["Locations"],
    }),
    getLocationByName: builder.query<object, string>({
      query: (name) => `Location/byName/${name}`,
      providesTags: ["Locations"],
    }),
    addLocation: builder.mutation<object, object>({
      query: (post) => ({
        url: "Location",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Locations"],
    }),
    deleteLocation: builder.mutation<object, string>({
      query: (id) => ({
        url: `Location/${id}`,
        method: "DELETE",
        credentials: "include",
      }),
      invalidatesTags: ["Locations"],
    }),
    updateLocation: builder.mutation<void, Location>({
      query: ({ id, ...rest }) => ({
        url: `Location/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Locations"],
    }),
    getVehicles: builder.query<Array<object>, string>({
      query: () => "Vehicle",
      providesTags: ["Vehicles"],
    }),
    getVehiclesById: builder.query<object, string>({
      query: (id) => `Vehicle/${id}`,
      providesTags: ["Vehicles"],
    }),
    addVehicle: builder.mutation<object, Vehicle>({
      query: (post) => ({
        url: "Vehicle",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Vehicles"],
    }),
    deleteVehicle: builder.mutation<object, string>({
      query: (id) => ({
        url: `Vehicle/${id}`,
        method: "DELETE",
        credentials: "include",
      }),
      invalidatesTags: ["Vehicles"],
    }),
    updateVehicle: builder.mutation<void, Vehicle>({
      query: ({ id, ...rest }) => ({
        url: `Vehicle/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Vehicles"],
    }),
    getTrips: builder.query<Array<object>, string>({
      query: () => "Trip",
      providesTags: ["Trips"],
    }),
    getTripsById: builder.query<any, string>({
      query: (id) => `Trip/${id}`,
      providesTags: ["Trips"],
    }),
    findTrip: builder.mutation<any, Book>({
      query: (post) => ({
        url: "Trip/find",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Trips"],
    }),
    addTrip: builder.mutation<object, object>({
      query: (post) => ({
        url: "Trip",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Trips"],
    }),
    deleteTrip: builder.mutation<void, string>({
      query: (id) => ({
        url: `Trip/${id}`,
        method: "DELETE",
        credentials: "include",
      }),
      invalidatesTags: ["Trips"],
    }),
    updateTrip: builder.mutation<void, Trip>({
      query: ({ id, ...rest }) => ({
        url: `Trip/${id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Trips"],
    }),
    getBookings: builder.query<Array<Booking>, string>({
      query: () => "Booking",
      providesTags: ["Bookings"],
    }),
    addBooking: builder.mutation<object, Booking>({
      query: (post) => ({
        url: "Booking/agentBooking",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Bookings"],
    }),
    deleteBooking: builder.mutation<void, string>({
      query: (id) => ({
        url: `Booking/${id}`,
        method: "DELETE",
        credentials: "include",
      }),
      invalidatesTags: ["Bookings"],
    }),
    getCancellations: builder.query<Array<Cancellation>, string>({
      query: () => "Booking/adminCancel",
      providesTags: ["Cancellations"],
    }),
    addCancellation: builder.mutation<object, Cancellation>({
      query: (post) => ({
        url: "Booking/adminCancel",
        method: "POST",
        body: post,
      }),
      invalidatesTags: ["Cancellations", "Bookings"],
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useLazyGetUserByIdQuery,
  useAddLocationMutation,
  useGetLocationsQuery,
  useGetLocationByNameQuery,
  useLazyGetLocationByIdQuery,
  useDeleteLocationMutation,
  useUpdateVehicleMutation,
  useGetVehiclesQuery,
  useLazyGetVehiclesByIdQuery,
  useAddVehicleMutation,
  useDeleteVehicleMutation,
  useUpdateLocationMutation,
  useDeleteTripMutation,
  useGetTripsQuery,
  useFindTripMutation,
  useLazyGetTripsByIdQuery,
  useAddTripMutation,
  useUpdateTripMutation,
  useAddBookingMutation,
  useGetBookingsQuery,
  useDeleteBookingMutation,
  useGetCancellationsQuery,
  useAddCancellationMutation,
} = myApi;
