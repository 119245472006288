import { UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Space, Table, Tag } from "antd";
import React from "react";
import {
  useGetBookingsQuery,
  useGetCancellationsQuery,
  useGetLocationsQuery,
  useGetTripsQuery,
  useGetUsersQuery,
  useGetVehiclesQuery,
} from "../redux/api";
import { Link } from "react-router-dom";
import { VehicleType } from "../models/vehicleType";

export default function Home() {
  const vehicles: any = useGetVehiclesQuery("");
  const users: any = useGetUsersQuery("");
  const trips: any = useGetTripsQuery("");
  const locations: any = useGetLocationsQuery("");
  const bookings: any = useGetBookingsQuery("");
  const cancellations: any = useGetCancellationsQuery("");
  let bookingData = [];

  const columns = [
    {
      title: "Booking Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Pick Up",
      dataIndex: "pick_location",
      key: "pick_location",
      render: (text: any, record: any) => (
        <Space size="middle">{getLocation(record.pick_location)}</Space>
      ),
    },
    {
      title: "Drop",
      dataIndex: "drop_location",
      key: "drop_location",
      render: (text: any, record: any) => (
        <Space size="middle">{getLocation(record.drop_location)}</Space>
      ),
    },
    {
      title: "Seats",
      dataIndex: "seats",
      key: "seats",
      render: (text: any, record: any) => (
        <Space size="middle">
          {getSeatsCount(record.adult_seats, record.children_seats)}
        </Space>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "is_paid",
      key: "is_paid",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.isPaid ? (
            <Tag color="success">Paid</Tag>
          ) : (
            <Tag color="warning">Not Paid</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "Journey Date",
      dataIndex: "trip_date",
      key: "trip_date",
      render: (text: any, record: any) => (
        <Space size="middle">{new Date(record.trip_date).toDateString()}</Space>
      ),
    },
  ];

  const getLocation = (id: string) => {
    let loc = "";
    if (locations && locations.data && locations.data?.value?.length > 0) {
      locations.data?.value?.forEach((location: any) => {
        if (Number(location.id) === Number(id)) {
          loc = location.name;
        }
      });
      return loc;
    }
    return "No location";
  };

  const getSeatsCount = (adultSeats: any, childSeats: any) => {
    let count = 0;
    if (adultSeats) {
      count = count + Number(adultSeats?.split(",").length);
    }
    if (childSeats) {
      count = count + Number(childSeats?.split(",").length);
    }
    return count;
  };
  if (bookings.data?.value) {
    bookingData = bookings.data?.value?.map((item: any) => {
      return { key: item.id, ...item };
    });
  }

  return (
    <>
      <h2>Dashboard</h2>
      <br></br>
      <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col className="gutter-row" span={6}>
          <Card style={{ backgroundColor: "#7367f0" }} className="card">
            <p className="card-number">{users.data?.value?.length}</p>
            <p className="card-text">Total Users</p>
            <Link to="/users">
              <Button size={"small"} className="card-button">
                View All
              </Button>
            </Link>
            <UserOutlined
              style={{ position: "absolute", bottom: 0, left: 0 }}
            />
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ backgroundColor: "#00bcd4" }} className="card">
            <p className="card-number">{trips.data?.value?.length}</p>
            <p className="card-text">Trips</p>
            <Link to="/trip">
              <Button size={"small"} className="card-button">
                View All
              </Button>
            </Link>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ backgroundColor: "#ff9800" }} className="card">
            <p className="card-number">{vehicles.data?.value?.length}</p>
            <p className="card-text">Vehicles</p>
            <Link to="/vehicles">
              <Button size={"small"} className="card-button">
                View All
              </Button>
            </Link>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card style={{ backgroundColor: "#e91e63" }} className="card">
            <p className="card-number">{locations.data?.value?.length}</p>
            <p className="card-text">Locations</p>
            <Link to="/location">
              <Button size={"small"} className="card-button">
                View All
              </Button>
            </Link>
          </Card>
        </Col>
      </Row>
      <Row gutter={[8, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col className="gutter-row" span={8}>
          <Card style={{ backgroundColor: "#28c76f" }} className="card">
            <p className="card-number">{VehicleType.length}</p>
            <p className="card-text">Vehicles</p>
            <Link to="/vehicles">
              <Button size={"small"} className="card-button">
                View All
              </Button>
            </Link>
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card style={{ backgroundColor: "#ff9f43" }} className="card">
            <p className="card-number">{bookings.data?.value?.length}</p>
            <p className="card-text">Bookings</p>
            <Link to="/tickets">
              <Button size={"small"} className="card-button">
                View All
              </Button>
            </Link>
          </Card>
        </Col>
        <Col className="gutter-row" span={8}>
          <Card style={{ backgroundColor: "#ea5455" }} className="card">
            <p className="card-number">{cancellations.data?.value?.length}</p>
            <p className="card-text">Cancellations</p>
            <Link to="/cancellations">
              <Button size={"small"} className="card-button">
                View All
              </Button>
            </Link>
          </Card>
        </Col>
      </Row>
      <h2>Latest Booking History</h2>
      <Table columns={columns} dataSource={bookingData}></Table>
    </>
  );
}
