import {
  DeleteFilled,
  EditFilled,
  LaptopOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  useDeleteTripMutation,
  useGetLocationsQuery,
  useGetTripsQuery,
  useLazyGetLocationByIdQuery,
} from "../../redux/api";
import Search from "antd/es/input/Search";
import { Location } from "../../models/Location";

export default function Trips() {
  const [searchedText, setSearchedText] = useState("");
  const [deleteTrip] = useDeleteTripMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const { isLoading, data }: any = useGetTripsQuery("");
  const [getLocationById, results] = useLazyGetLocationByIdQuery();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [titleToDelete, setTitleToDelete] = useState("");
  const [idToDelete, setIdToDelete] = useState("");
  const locations: any = useGetLocationsQuery("");
  let filteredData = data;
  if (filteredData) {
    filteredData = data?.value?.map((item: any) => {
      return { key: item.id, ...item };
    });
    console.log(data);
  }

  const getLocation = (id: string) => {
    let loc = "";
    if (locations && locations.data && locations.data?.value?.length > 0) {
      locations.data?.value?.forEach((location: any) => {
        if (location.id === id) {
          loc = location.name;
        }
      });
      return loc;
    }
    return loc;
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(record.title)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.from)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.to).toLowerCase().includes(String(value).toLowerCase())
        );
      },
    },
    {
      title: "Starting point",
      dataIndex: "from",
      key: "from",
      render: (text: any, record: any) => (
        <Space size="middle">{getLocation(record.stoppage[0].id)}</Space>
      ),
    },
    {
      title: "Ending point",
      dataIndex: "to",
      key: "to",
      render: (text: any, record: any) => (
        <Space size="middle">
          {getLocation(record.stoppage[record.stoppage.length - 1].id)}
        </Space>
      ),
    },
    {
      title: "Distance",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Time",
      dataIndex: "approax_time",
      key: "approax_time",
    },
    {
      title: 'active',
      dataIndex: "is_active",
      key: "is_active",
      render: (val: boolean) => <div>{val? 'yes': 'no'}</div>
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/tripDetail/${record.id}` }}>
            <Space>
              <EditFilled />
            </Space>
          </Link>
          {/* <a>
            <Space>
              <DeleteFilled onClick={() => showModal(record)} />
            </Space>
          </a> */}
        </Space>
      ),
    },
  ];

  const showModal = (record: any) => {
    setTitleToDelete(record.title);
    setIdToDelete(record.id);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteTrip(idToDelete)
      .then(() => {
        setIsModalOpen(false);
        message.success(`Trip ${idToDelete} is deleted successfully.`);
      })
      .catch((error) => {
        message.error(`Failed to delete the trip ${idToDelete}.`);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {contextHolder}
      <Modal
        title="Delete Vehicle"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete"
        okButtonProps={{
          danger: true,
          value: "Delete",
          icon: <DeleteFilled />,
        }}
      >
        <p>
          Are you sure you want to delete <b>{titleToDelete}</b> trip?
        </p>
      </Modal>
      <h2>
        Trip List
        <Search
          className="search"
          placeholder="Search Trip"
          onSearch={(currValue) => {
            setSearchedText(currValue);
          }}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
          enterButton
        />
        <Link to="/tripDetail">
          <Button className="add-button">
            <PlusOutlined /> Add Trip
          </Button>
        </Link>
      </h2>
      <Divider></Divider>
      <Table columns={columns} dataSource={filteredData}></Table>
    </>
  );
}
