import {
  DeleteFilled,
  EditFilled,
  LaptopOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Divider, Input, Modal, Space, Table, message } from "antd";
import { useDeleteVehicleMutation, useGetVehiclesQuery } from "../../redux/api";
import { useState } from "react";
import Search from "antd/es/input/Search";
import { Link } from "react-router-dom";
import { Vehicle } from "../../models/Vehicle";

export default function Vehicles() {
  const [searchedText, setSearchedText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      // key: "engNo",
    },
    {
      title: "Reg No.",
      dataIndex: "reg_no",
      // key: "regNo",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(record.reg_no)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.eng_no)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.model_no)
            .toLowerCase()
            .includes(String(value).toLowerCase())
        );
      },
    },
    {
      title: "Eng No.",
      dataIndex: "eng_no",
      // key: "engNo",
    },
    {
      title: "Model No.",
      dataIndex: "model_no",
      // key: "modelNo",
    },
    {
      title: "Type",
      dataIndex: "type",
      // key: "fleetType",
    },
    {
      title: "Chassis No.",
      dataIndex: "chasis_no",
      // key: "chassisNo",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      // key: "owner",
    },
    {
      title: "Owner Mobile",
      dataIndex: "owner_number",
      // key: "ownerMobile",
    },
    {
      title: "Company",
      dataIndex: "company",
      // key: "company",
    },
    {
      title: "Action",
      dataIndex: "action",
      // key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/vehicleDetail/${record.id}` }}>
            <Space>
              <EditFilled />
            </Space>
          </Link>

          <Space>
            <DeleteFilled onClick={() => showModal(record)} />
          </Space>
        </Space>
      ),
    },
  ];
  const [deleteVehicle] = useDeleteVehicleMutation();
  const { isLoading, data }: any = useGetVehiclesQuery("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regNoToDelete, setRegNoToDelete] = useState("");
  const [idToDelete, setIdToDelete] = useState("");

  let filteredData = data;
  if (filteredData) {
    filteredData = data?.value?.map((item: any) => {
      return { key: item.id, ...item };
    });
  }

  const showModal = (record: any) => {
    setRegNoToDelete(record.regNo);
    setIdToDelete(record.id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    deleteVehicle(idToDelete)
      .then(() => {
        setIsModalOpen(false);
        message.success(`Vehicle ${regNoToDelete} is deleted successfully.`);
      })
      .catch((error) => {
        message.error(`Failed to delete vehicle ${regNoToDelete}.`);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Delete Vehicle"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete"
        okButtonProps={{
          danger: true,
          value: "Delete",
          icon: <DeleteFilled />,
        }}
      >
        <p>
          Are you sure you want to delete <b>{regNoToDelete}</b> vehicle?
        </p>
      </Modal>
      <h2>
        Vehicles
        <Search
          className="search"
          placeholder="Search Vehicle"
          onSearch={(currValue) => {
            setSearchedText(currValue);
          }}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
          enterButton
        />
        <Link to="/vehicleDetail">
          <Button className="add-button">
            <PlusOutlined /> Add Vehicle
          </Button>
        </Link>
      </h2>
      <Divider />
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
      ></Table>
    </>
  );
}
