import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  SelectProps,
  message,
} from "antd";
import { Cancellation } from "../../models/Cancellation";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAddCancellationMutation,
  useDeleteBookingMutation,
} from "../../redux/api";

export function CancelTicket() {
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const { bookingId } = useParams();
  const [addCancellation] = useAddCancellationMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteBooking] = useDeleteBookingMutation();
  const payTypeOptions: SelectProps["options"] = [
    { label: "Cash", value: "cash" },
    { label: "Online", value: "online" },
  ];
  const submitHandler = (values: any) => {

   
      let cancellation: Cancellation = { ...values };
      cancellation.booking_id = Number(bookingId);
      addCancellation(cancellation).then((result: any) => {
        if (result.error) {
          message.error(`Failed to cancel the tiket`);
        } else {
          message.success(`Cancelled successfully`);
          navigate("/cancellations");
        }
      });
    // deleteBooking(bookingId!)
    //   .then((result: any) => {
    //     if (result.error) {
    //       message.error(`Failed to remove the booking ${bookingId}`);
    //     } else {
    //       let cancellation: Cancellation = { ...values };
    //       cancellation.booking_id = Number(bookingId);
    //       message.success(`Booking ${bookingId} is removed successfully`);
    //       addCancellation(cancellation).then((result: any) => {
    //         if (result.error) {
    //           message.error(`Failed to add the cancellation`);
    //         } else {
    //           message.success(`Cancellation is added successfully`);
    //           navigate("/cancellations");
    //         }
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     message.error(`Failed to cancel the booking ${values.bookingId}`);
    //   });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  return (
    <div>
      {contextHolder}
      <h2>Ticket Cancel</h2>
      <Divider></Divider>
      <Form
        form={form}
        layout="vertical"
        onFinish={submitHandler}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <Form.Item<Cancellation>
              name="booking_id"
              className="form-item"
              label="Booking Id"
            >
              <Input disabled defaultValue={bookingId} value={bookingId} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item<Cancellation>
              name="cancel_fee"
              className="form-item"
              label="Cancel Fee"
              rules={[
                { required: true, message: "Please input cancellation fee!" },
              ]}
            >
              <Input placeholder="Cancel Fee" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <Form.Item<Cancellation>
              name="payment_type"
              className="form-item"
              label="Payment Type"
              rules={[
                { required: true, message: "Please input payment type!" },
              ]}
            >
              <Select options={payTypeOptions} placeholder="Payment Type" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item<Cancellation>
              name="details"
              className="form-item"
              label="Payment Details"
            >
              <Input placeholder="Payment Details" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Link to="/tickets">
            <Button className="btn-cancel" type="primary">
              Cancel
            </Button>
          </Link>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
