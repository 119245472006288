import {
  Button,
  Divider,
  Form,
  Input,
  Row,
  Col,
  message,
  Radio,
  Select,
  SelectProps,
  Upload,
  Modal,
  UploadProps,
  UploadFile,
  InputNumber,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useAddUserMutation,
  useAddVehicleMutation,
  useLazyGetVehiclesByIdQuery,
  useUpdateVehicleMutation,
} from "../../redux/api";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  FileImageFilled,
  FileImageOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { MouseEvent, useEffect, useState } from "react";
import { RcFile } from "antd/es/upload";
import { Vehicle } from "../../models/Vehicle";
import { successMsg } from "../../message";
import { VehicleType } from "../../models/vehicleType";
import Vehicles from "./vehicles";

function VehicleDetail() {
  const { vehicleId } = useParams();
  const [getVehicleById, results]: any = useLazyGetVehiclesByIdQuery();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [editMode, setEditMode] = useState(false);
  const [updateVehicle] = useUpdateVehicleMutation();
  const [file, setFile] = useState({});
  const [addVehicle] = useAddVehicleMutation();
  let navigate = useNavigate();
  useEffect(() => {
    if (vehicleId) {
      getVehicleById(vehicleId!, true);
      if (results && results.data && results.data.value) {
        setEditMode(true);
        form.setFieldsValue(results.data.value);
      }
    } else {
      setEditMode(false);
    }
  }, [results]);

  const vehicleOptions: SelectProps["options"] = [];
  VehicleType.forEach((vehicle) => {
    vehicleOptions.push({
      label: vehicle.name,
      value: vehicle.name,
    });
  });

  const submitHandler = (values: Vehicle) => {
    const vehicle = VehicleType.find((vehicle) => values.type === vehicle.name);
    const seats: any = vehicle?.seats;
    values.seats = seats;
    if (editMode) {
      values.id = vehicleId!;
      updateVehicle(values).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to update vehicle ${values.reg_no}.\n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`Vehicle ${values.reg_no} is updated successfully.`);
          navigate("/vehicles");
        }
      });
    } else {
      addVehicle({ ...values }).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to add vehicle ${values.reg_no}.\n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`Vehicle ${values.reg_no} is updated successfully.`);
          navigate("/vehicles");
        }
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      {contextHolder}
      <h2>Add Vehicle</h2>
      <Divider></Divider>
      <Form
        form={form}
        layout="vertical"
        onFinish={submitHandler}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="reg_no"
              rules={[
                { required: true, message: "Please enter registration no.!" },
              ]}
              className="form-item"
              label="Reg No."
            >
              <Input placeholder="Reg No." />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="eng_no"
              className="form-item"
              label="Eng No."
              rules={[{ required: true, message: "Please enter engine no.!" }]}
            >
              <Input placeholder="Eng No." />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="model_no"
              className="form-item"
              label="Model No."
              rules={[{ required: true, message: "Please enter model no.!" }]}
            >
              <Input placeholder="Model No." />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="type"
              className="form-item"
              label="Vehicle Type"
              rules={[{ required: true, message: "Please enter fleet type!" }]}
            >
              <Select
                options={vehicleOptions}
                placeholder="Vehicle type"
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="chasis_no"
              className="form-item"
              label="Chassis No."
              rules={[{ required: true, message: "Please enter chassis no!" }]}
            >
              <Input placeholder="Chassis No." />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="owner"
              className="form-item"
              label="Owner"
              rules={[{ message: "Please enter owner name!" }]}
            >
              <Input placeholder="Owner name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="company"
              className="form-item"
              label="Company"
              rules={[
                { required: true, message: "Please enter company name!" },
              ]}
            >
              <Input placeholder="Company name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="owner_number"
              className="form-item"
              label="Owner Mobile"
              rules={[
                { required: true, message: "Please enter owner mobile no.!" },
              ]}
            >
              <Input placeholder="Mobile No." />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col className="gutter-row">
            <Form.Item<Vehicle>
              className="form-item"
              label="Seats"
              name="seats"
              rules={[
                { required: true, message: "Please enter total seats.!" },
              ]}
            >
              <InputNumber className="input-number" placeholder="Total seats" />
            </Form.Item>
          </Col> */}
        {/* <Col className="gutter-row" span={6}>
            <Form.Item<Vehicle>
              name="status"
              className="form-item"
              label="Status"
              rules={[{ required: true, message: "Please select status!" }]}
            >
              <Radio.Group>
                <Radio value={"active"}>Active</Radio>
                <Radio value={"disable"}>Disable</Radio>
              </Radio.Group>
            </Form.Item>
          </Col> */}
        {/* </Row> */}
        <Form.Item>
          <Link to="/vehicles">
            <Button className="btn-cancel" type="primary">
              Cancel
            </Button>
          </Link>
          {vehicleId ? (
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}

export default VehicleDetail;
