import React, { useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import DashboardLayout from "./components/dashboardLayout";
import Home from "./pages/home";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { Switch } from "antd";
import AdminLogin from "./pages/adminLogin";
import { RootState, store } from "./redux/store";
import { useSelector } from "react-redux";

function App() {
  const { isAuthenticated } = useSelector((state: RootState) => state.root);
  const userData: any = localStorage.getItem("userName");
  return (
    <BrowserRouter>
      {!userData && !isAuthenticated ? (
        <AdminLogin />
      ) : (
        <DashboardLayout>
          <Home></Home>
        </DashboardLayout>
      )}
    </BrowserRouter>
  );
}

export default App;
