import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { myApi } from "./api";
import { myreducer, rootReducer } from "./reducer";

export const store = configureStore({
  reducer: {
    root: rootReducer,
    [myApi.reducerPath]: myApi.reducer,
    [myreducer.name]: myreducer.reducer,
  },
  middleware: (defaultMiddleware) =>
    defaultMiddleware().concat(myApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
