import React, { ReactNode, useState } from "react";
import { Route, Router, useNavigate } from "react-router-dom";
import {
  CarFilled,
  DashOutlined,
  EnvironmentFilled,
  FileDoneOutlined,
  FolderFilled,
  HomeOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Button,
  Layout,
  Menu,
  MenuProps,
  Switch,
  message,
  theme,
} from "antd";
import AppRoutes from "../AppRoutes";
import { contextHolder } from "../../message";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import "../../assets/BusLogo.jpg";
import AdminLogin from "../../pages/adminLogin";

const { Header, Content, Footer, Sider } = Layout;
export interface DashboardLayoutProps {
  children: ReactNode;
}

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "dashboard", <HomeOutlined />),
  getItem("Manage Users", "sub1", <UserOutlined />, [
    getItem("All Users", "users"),
  ]),
  getItem("Booking History", "sub3", <FileDoneOutlined />, [
    getItem("Booked Tickets", "tickets"),
    getItem("Book Ticket", "booking"),
    getItem("Cancellations", "cancellations"),
  ]),
  getItem("Manage Vehicles", "sub4", <CarFilled />, [
    getItem("Vehicles", "vehicles"),
  ]),
  getItem("Manage Locations", "sub5", <EnvironmentFilled />, [
    getItem("Location List", "location"),
  ]),
  getItem("Manage Trips", "sub6", <CarFilled />, [getItem("Trips", "trip")]),
];

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
}: DashboardLayoutProps) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const { isAuthenticated } = useSelector((state: RootState) => state.root);
  let navigate = useNavigate();
  const logoutHandler = () => {
    dispatch({ type: "logout" });
    localStorage.clear();
    navigate("/");
    message.success("User is successfully logged out");
  };
  const userName: any = localStorage.getItem("userName");
  const userEmail: any = localStorage.getItem("userEmail");
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <>
      {userName ? (
        <Layout>
          <Sider
            className="sider"
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
          >
            <div className="demo-logo-vertical">
              <h3>NestTrip</h3>
              <img src={require("../../assets/BusLogo.jpg")} alt="" />
            </div>
            <Menu
              theme="light"
              // onClick={(e) => setSelectedMenuItem(e.key)}
              onClick={(info) => {
                console.log(info.key);
                // uwse("/Users");
                // this.props
                navigate(info.key);
              }}
              mode="inline"
              defaultSelectedKeys={["4"]}
              // items={[
              //   UserOutlined,
              //   VideoCameraOutlined,
              //   UploadOutlined,
              //   UserOutlined,
              // ].map((icon, index) => ({
              //   key: String(index + 1),
              //   icon: React.createElement(icon),
              //   label: `nav ${index + 1}`,
              // }))}
              items={items}
            />
          </Sider>
          <Layout>
            <Header style={{ padding: 0, background: "#7367f0" }}>
              <div className="logout">
                <Avatar
                  style={{ backgroundColor: "#87d068" }}
                  icon={<UserOutlined />}
                />
                {userEmail}
                <Button danger size="small" onClick={logoutHandler}>
                  Logout
                </Button>
              </div>
            </Header>
            <Content style={{ margin: "24px 16px 0" }}>
              <div
                style={{
                  padding: 24,
                  maxHeight: "calc(100vh - 153px)",
                  background: colorBgContainer,
                  overflowY: "scroll",
                  height: "100vh",
                }}
              >
                {/* {children} */}
                <AppRoutes />
              </div>
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
            >
              NestTrip ©2023 Created by{" "}
              <a href="https://designslibrary.com">Designslibrary</a>
            </Footer>
          </Layout>
        </Layout>
      ) : (
        <AdminLogin />
      )}
    </>
  );
};

export default DashboardLayout;
