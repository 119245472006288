import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  DatePickerProps,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  Radio,
  Row,
  Select,
  SelectProps,
  Space,
  message,
} from "antd";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import {
  useAddBookingMutation,
  useGetLocationsQuery,
  useGetUsersQuery,
  useFindTripMutation,
} from "../../redux/api";
import { useEffect, useState } from "react";
import { Book } from "../../models/Book";
import { Trip } from "../../models/Trip";
import { Booking } from "../../models/Booking";
import { VehicleType } from "../../models/vehicleType";
import { DefaultOptionType } from "antd/es/select";

export default function BookTicket() {
  const [form] = Form.useForm();
  const [bookingForm] = Form.useForm();
  let navigate = useNavigate();
  const [isSubmitClicked, setSubmitClicked] = useState<boolean>(false);
  const [messageApi, contextHolder] = message.useMessage();
  const locations: any = useGetLocationsQuery("");
  const users: any = useGetUsersQuery("");
  const [tripData, setTripData] = useState<Trip[]>();
  const [journeyDate, setJourneyDate] = useState<any>("");
  const [selectedTrip, setSelectedTrip] = useState<Trip>();
  const [selectedChildCount, setSelectedChildCount] = useState<number>(0);
  const [selectedAdultCount, setSelectedAdultCount] = useState<number>(0);
  const [formValues, setFormValues] = useState({
    from: "",
    to: "",
    date: "",
  });
  const tax = 56;
  const [showForm, setShowForm] = useState(false);
  const [findTrip, results] = useFindTripMutation();
  const options: SelectProps["options"] = [];
  const [addBooking] = useAddBookingMutation();

  const getLocation = (id: any) => {
    let loc = "";
    if (locations && locations.data && locations.data?.value?.length > 0) {
      locations.data?.value?.forEach((location: any) => {
        if (location.id === id) {
          loc = location.name;
        }
      });
      return loc;
    }
    return loc;
  };

  const locationOptions: SelectProps["options"] = [];
  locations.data?.value?.forEach((location: any) => {
    locationOptions.push({
      label: location.name,
      value: location.id,
    });
  });

  const userOptions: SelectProps["options"] = [];
  users.data?.value?.forEach((user: any) => {
    userOptions.push({
      label: user.name,
      value: user.id,
    });
  });
  useEffect(() => {
    if (results && results.data && results.data.value) {
      setTripData(results.data.value);
    }
  }, [results]);

  VehicleType.forEach((vehicle: any) => {
    options.push({
      label: vehicle,
      value: vehicle,
    });
  });

  const submitHandler = (values: any) => {
    setSubmitClicked(true);
    setFormValues({
      from: values.from,
      to: values.to,
      date: journeyDate,
    });
    findTrip({
      from: values.from,
      to: values.to,
      date: journeyDate,
    });
    setShowForm(false);
  };

  const bookingFormSubmitHandler = (values: any) => {
    if (selectedTrip) {
      const booking: Booking = {
        user_id: values.user_id,
        agent_id: "",
        trip_id: selectedTrip.id,
        trip_title: selectedTrip.title,
        pick_location: values.pick_location,
        drop_location: values.drop_location,
        start_time: selectedTrip.stoppage[0].time,
        end_time: selectedTrip.stoppage[selectedTrip.stoppage.length - 1].time,
        adult_seats: selectedAdultCount.toString(),
        children_seats: selectedChildCount.toString(),
        transaction_id: "",
        amount: calculateTotal(),
        trip_date: formValues.date,
        is_paid: values.paymentStatus,
      };
      addBooking(booking).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to add booking. \n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`Booking is created successfully.`);
          navigate("/tickets");
        }
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const onChange: DatePickerProps["onChange"] = (date, dateString) => {
    setJourneyDate(date?.format("YYYY-MM-DD"));
  };

  const openForm = (item: Trip) => {
    if (selectedTrip?.id === item.id && showForm === true) {
      setShowForm(false);
    } else {
      setShowForm(false);
      setSelectedTrip(item);
      setShowForm(true);
    }
  };

  const childChangeHandler = (value: any) => {
    setSelectedChildCount(value);
  };
  const adultChangeHandler = (value: any) => {
    setSelectedAdultCount(value);
  };

  function calculateTotal(): string {
    return String(
      // selectedChildCount! * Number(selectedTrip?.child_fare!) +
      //   selectedAdultCount! * Number(selectedTrip?.adult_fare!) +
      //   (selectedAdultCount! + selectedChildCount!) * tax

      selectedAdultCount! * Number(selectedTrip?.adult_fare!) +
        selectedAdultCount! * tax
    );
  }

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  // eslint-disable-next-line arrow-body-style
  const disabledDate: DatePickerProps["disabledDate"] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().subtract(1, "day").endOf("day");
  };

  return (
    <div>
      {contextHolder}
      <h2>Book Ticket</h2>
      <Divider></Divider>
      <Form
        form={form}
        layout="vertical"
        onFinish={submitHandler}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item<Book>
              name="from"
              rules={[
                { required: true, message: "Please input from location!" },
              ]}
              className="form-item"
              label="From"
              valuePropName="value"
            >
              <Select options={locationOptions} placeholder="From"></Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Book>
              name="to"
              // rules={[{ required: true, message: "Please input destination!" }]}
              className="form-item"
              label="To"
              valuePropName="value"
            >
              <Select options={locationOptions} placeholder="To"></Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row">
            <Form.Item<Book>
              name="date"
              className="form-item"
              label="Journey Date"
              rules={[
                { required: true, message: "Please input journey date!" },
              ]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                onChange={onChange}
                placeholder="Journey Date"
                disabledDate={disabledDate}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Divider></Divider>
      {tripData && tripData.length > 0 && isSubmitClicked ? (
        <div className="trasition-div">
          <div>
            <p>Showing results for </p>{" "}
            <h3>
              {journeyDate ? new Date(journeyDate).toLocaleDateString() : ""}
            </h3>
          </div>
          <List
            itemLayout="horizontal"
            dataSource={tripData}
            renderItem={(item: Trip, index) => (
              <>
                <div className="trip-card">
                  <Card>
                    <div className="col text-center">
                      <h4>{item?.title}</h4>
                      <p>{item?.title}</p>
                    </div>
                    <Divider type="vertical" />
                    <div className="col text-center">
                      <h4>{getLocation(item?.stoppage[0].id)}</h4>
                      <p>{item?.start_time}</p>
                    </div>
                    <Divider type="vertical" />
                    <div className="col text-center">
                      <h4>
                        {getLocation(
                          item?.stoppage[item.stoppage.length - 1].id
                        )}
                      </h4>
                      <p>{item?.end_time}</p>
                    </div>
                    <Divider type="vertical" />
                    <div className="col text-center">
                      <h4>Distance</h4>
                      <p>{item?.distance}</p>
                    </div>
                    <Divider type="vertical" />
                    <div className="col text-center">
                      <h4>Fair</h4>
                      <p>{item?.start_time}</p>
                    </div>
                    <Divider type="vertical" />
                    <div className="col text-center">
                      <h4>Seat</h4>
                      <p>{item?.start_time}</p>
                    </div>
                    <Divider type="vertical" />
                    <Button
                      value={item.title}
                      className="add-button"
                      onClick={() => openForm(item)}
                    >
                      Details
                    </Button>
                  </Card>
                </div>
                {showForm && selectedTrip?.id === item.id ? (
                  <div className="book-form">
                    <Form
                      form={bookingForm}
                      onFinish={bookingFormSubmitHandler}
                    >
                      <Row gutter={16}>
                        <Col className="gutter-row">
                          <Form.Item
                            className="form-item"
                            label="Total child"
                            name="child_count"
                          >
                            <InputNumber
                              className="input-number"
                              onChange={childChangeHandler}
                              placeholder="Total child"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                          <Form.Item
                            rules={[
                              {
                                required: true,
                                message: "Please input number of adults!",
                              },
                            ]}
                            className="form-item"
                            label="Total adult"
                            name="adult_count"
                          >
                            <InputNumber
                              className="input-number"
                              onChange={adultChangeHandler}
                              placeholder="Total adult"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            className="form-item"
                            rules={[
                              {
                                required: true,
                                message: "Please select a user!",
                              },
                            ]}
                            name="user_id"
                            label="User"
                          >
                            <Select
                              showSearch
                              options={userOptions}
                              optionFilterProp="children"
                              placeholder="User"
                              filterOption={filterOption}
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="paymentStatus"
                            className="form-item"
                            label="Payment Status"
                          >
                            <Radio.Group>
                              <Radio value={true}>Paid</Radio>
                              <Radio value={false}>Not Paid</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="pick_location"
                            rules={[
                              {
                                required: true,
                                message: "Please input pick up location!",
                              },
                            ]}
                            className="form-item"
                            label="Pick Up Location"
                            valuePropName="value"
                          >
                            <Select
                              options={locationOptions}
                              placeholder="Pick Up"
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={6}>
                          <Form.Item
                            name="drop_location"
                            rules={[
                              {
                                required: true,
                                message: "Please input drop location!",
                              },
                            ]}
                            className="form-item"
                            label="Drop Location"
                            valuePropName="value"
                          >
                            <Select
                              options={locationOptions}
                              placeholder="Drop"
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={5}>
                          <b>Type</b>
                        </Col>
                        <Col span={5}>
                          <b>Price</b>
                        </Col>
                        <Col span={5}>
                          <b>Total</b>
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={16}>
                        <Col span={5}>Adult</Col>
                        <Col span={5}>{selectedTrip?.adult_fare}</Col>
                        <Col span={5}>
                          {selectedAdultCount *
                            Number(selectedTrip?.adult_fare!)}
                        </Col>
                      </Row>
                      {/* <Row gutter={16}>
                        <Col span={5}>Child</Col>
                        <Col span={5}>{selectedTrip?.child_fare}</Col>
                        <Col span={5}>
                          {selectedChildCount! *
                            Number(selectedTrip?.child_fare!)}
                        </Col>
                      </Row> */}
                      <Divider />
                      <Row gutter={16}>
                        <Col span={5}>Ticket Price</Col>
                        <Col span={5}></Col>
                        <Col span={5}>
                          {/* {selectedChildCount! *
                            Number(selectedTrip?.child_fare!) +
                            selectedAdultCount! *
                              Number(selectedTrip?.adult_fare!)} */}
                          {selectedAdultCount! *
                            Number(selectedTrip?.adult_fare!)}
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={5}>Tax</Col>
                        <Col span={5}></Col>
                        <Col span={5}>
                          {/* {(selectedAdultCount! + selectedChildCount!) * tax} */}
                          {selectedAdultCount! * tax}
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={5}>Grand Total</Col>
                        <Col span={5}></Col>
                        <Col span={5}>
                          <b>{calculateTotal()}</b>
                        </Col>
                      </Row>
                      <Divider />
                      <Button className="book-btn" htmlType="submit">
                        Book
                      </Button>
                    </Form>
                  </div>
                ) : (
                  ""
                )}
                <Divider></Divider>
              </>
            )}
          />
        </div>
      ) : isSubmitClicked ? (
        <h2>No Trip Found</h2>
      ) : (
        ""
      )}
    </div>
  );
}
