import {
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Divider, Space, Table, Tag, message } from "antd";
import { useGetBookingsQuery, useGetLocationsQuery } from "../../redux/api";
import { Link } from "react-router-dom";
import Search from "antd/es/input/Search";
import { useState } from "react";

export default function TicketList() {
  const { isLoading, data }: any = useGetBookingsQuery("");
  const [searchedText, setSearchedText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const locations: any = useGetLocationsQuery("");
  let filteredData = data;
  if (filteredData) {
    filteredData = data?.value?.map((item: any) => {
      return { key: item.id, ...item };
    });
  }
  const getLocation = (id: string) => {
    let loc = "";
    if (locations && locations.data && locations.data?.value?.length > 0) {
      locations.data?.value?.forEach((location: any) => {
        if (Number(location.id) === Number(id)) {
          loc = location.name;
        }
      });
      return loc;
    }
    return loc;
  };
  const getSeatsCount = (adultSeats: any, childSeats: any) => {
    let count = 0;
    if (adultSeats) {
      count = count + Number(adultSeats?.split(",").length);
    }
    if (childSeats) {
      count = count + Number(childSeats?.split(",").length);
    }
    return count;
  };
  const columns = [
    {
      title: "Booking Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "user_id"
    },
    {
      title: "Pick Up",
      dataIndex: "pick_location",
      key: "pick_location",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(getLocation(record.pick_location))
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.id)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(getLocation(record.drop_location))
            .toLowerCase()
            .includes(String(value).toLowerCase())
        );
      },
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.pick_location ? getLocation(record.pick_location) : ""}
        </Space>
      ),
    },
    {
      title: "Drop",
      dataIndex: "drop_location",
      key: "drop_location",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.drop_location ? getLocation(record.drop_location) : ""}
        </Space>
      ),
    },
    {
      title: "Seats",
      dataIndex: "seats",
      key: "seats",
      render: (text: any, record: any) => (
        <Space size="middle">
          {getSeatsCount(record.adult_seats, record.children_seats)}
        </Space>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "is_paid",
      key: "is_paid",
      render: (text: any, record: any) => (
        <Space size="middle">
          {record.isPaid ? (
            <Tag color="success">Paid</Tag>
          ) : (
            <Tag color="warning">Not Paid</Tag>
          )}
        </Space>
      ),
    },
    {
      title: "Journey Date",
      dataIndex: "trip_date",
      key: "trip_date",
      render: (text: any, record: any) => (
        <Space size="middle">{new Date(record.trip_date).toDateString()}</Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/cancelTicket/${record.id}` }}>
            <Space>
              <Button type="primary" danger>
                <CloseCircleOutlined />
                Cancel
              </Button>
            </Space>
          </Link>
        </Space>
      ),
    },
  ];
  return (
    <>
      {contextHolder}
      <h2>
        Ticket List
        <Search
          className="search"
          placeholder="Search Tickets"
          onSearch={(currValue) => {
            setSearchedText(currValue);
          }}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
          enterButton
        />
        <Link to="/booking">
          <Button className="add-button">
            <PlusOutlined /> Book ticket
          </Button>
        </Link>
      </h2>
      <Divider></Divider>
      <Table columns={columns} dataSource={filteredData}></Table>
    </>
  );
}
