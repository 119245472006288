import {
  DeleteFilled,
  EditFilled,
  LaptopOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Divider, Modal, Row, Space, Spin, Table, message } from "antd";
import { useGetUsersQuery } from "../../redux/api";
import { Link } from "react-router-dom";
import { useState } from "react";
import Search from "antd/es/input/Search";

export default function Users() {
  const { isLoading, data }: any = useGetUsersQuery("");
  const [searchedText, setSearchedText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userNameToDelete, setuserNameToDelete] = useState("");
  const [userIdToDelete, setUserIdToDelete] = useState("");

  let filteredData = data;
  if (filteredData) {
    filteredData = data?.value?.map((item: any) => {
      return { key: item.id, ...item };
    });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(record.name)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.email)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.country)
            .toLowerCase()
            .includes(String(value).toLowerCase())
        );
      },
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: "Country",
    //   dataIndex: "country",
    //   key: "country",
    // },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any, record: any) => (
        <Space size="middle">
          {new Date(record.created_at).toDateString()}
        </Space>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/userDetail/${record.id}` }}>
            <Space>
              <EditFilled />
            </Space>
          </Link>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <h2>
        Users
        <Search
          className="search"
          placeholder="Search User"
          onSearch={(currValue) => {
            setSearchedText(currValue);
          }}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
          enterButton
        />
        <Link to="/userDetail">
          <Button className="add-button">
            <PlusOutlined /> Add User
          </Button>
        </Link>
      </h2>
      <Divider></Divider>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={filteredData}
      ></Table>
    </>
  );
}
