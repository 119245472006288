import { Button, Divider, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import {
  useAddLocationMutation,
  useLazyGetLocationByIdQuery,
  useGetLocationsQuery,
  useUpdateLocationMutation,
} from "../../redux/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Location } from "../../models/Location";

export default function LocationDetail() {
  const { locationId } = useParams();
  const [updateLocation] = useUpdateLocationMutation();
  const [form] = Form.useForm();
  const [getLocationById, results]: any = useLazyGetLocationByIdQuery();
  const [messageApi, contextHolder] = message.useMessage();
  let navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [addLocation] = useAddLocationMutation();

  useEffect(() => {
    if (locationId) {
      getLocationById(locationId!, true);
      if (results && results.data?.value) {
        setEditMode(true);
        form.setFieldsValue(results.data.value);
      }
    } else {
      setEditMode(false);
    }
  }, [results]);

  const submitHandler = (values: any) => {
    if (editMode) {
      updateLocation({ id: locationId, ...values }).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to update location ${values.name}.\n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`Location ${values.name} is updated successfully.`);
          navigate("/location");
        }
      });
    } else {
      addLocation(values).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to add location ${values.name}. \n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`Location ${values.name} is added successfully.`);
          navigate("/location");
        }
      });
    }
    navigate("/location");
  };
  return (
    <>
      <h2>Add Location</h2>
      <Divider></Divider>
      <Form layout="vertical" form={form} onFinish={submitHandler}>
        <Form.Item<Location>
          required
          className="form-item"
          label="Location Name"
          name={"name"}
        >
          <Input placeholder="Location Name" />
        </Form.Item>
        <Form.Item<Location>
          required
          className="form-item"
          label="State"
          name={"state"}
        >
          <Input placeholder="State" />
        </Form.Item>
        <Form.Item>
          <Link to="/location">
            <Button className="btn-cancel" type="primary">
              Cancel
            </Button>
          </Link>
          {locationId ? (
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
}
