import { Route, Routes } from "react-router-dom";
import Users from "../../pages/users/Users";
import Home from "../../pages/home";
import UserDetail from "../../pages/users/UserDetail";
import Location from "../../pages/location/location";
import Trip from "../../pages/trip/trips";
import TripDetail from "../../pages/trip/tripDetail";
import LocationDetail from "../../pages/location/locationDetail";
import TicketList from "../../pages/booking/tickets";
import Vehicles from "../../pages/vehicle/vehicles";
import VehicleDetail from "../../pages/vehicle/vehicleDetail";
import Booking from "../../pages/booking/booking";
import AdminLogin from "../../pages/adminLogin";
import { CancelTicket } from "../../pages/booking/cancelTicket";
import { Cancellations } from "../../pages/booking/cancellations";
import { useSelector } from "react-redux";
function AppRoutes() {
  return (
    <Routes>
      <Route path="/home" element={<Home />} />
      <Route path="/dashboard" element={<Home />} />
      <Route path="/users" element={<Users />}></Route>
      <Route path="/userDetail/:userId" element={<UserDetail />}></Route>
      <Route path="/userDetail" element={<UserDetail />}></Route>
      <Route path="/vehicles" element={<Vehicles />}></Route>
      <Route
        path="/vehicleDetail/:vehicleId"
        element={<VehicleDetail />}
      ></Route>
      <Route path="/vehicleDetail" element={<VehicleDetail />}></Route>
      <Route path="/tripDetail" element={<TripDetail />}></Route>
      <Route path="/tripDetail/:tripId" element={<TripDetail />}></Route>
      <Route path="/location" element={<Location />}></Route>
      <Route path="/trip" element={<Trip />}></Route>
      <Route path="/tripDetail/:tripId" element={<TripDetail />}></Route>
      <Route path="/locationDetail" element={<LocationDetail />}></Route>
      <Route
        path="/locationDetail/:locationId"
        element={<LocationDetail />}
      ></Route>
      <Route path="/tickets" element={<TicketList />}></Route>
      <Route path="/" element={<AdminLogin />}></Route>
      <Route path="/booking" element={<Booking />}></Route>
      <Route path="/cancelTicket/:bookingId" element={<CancelTicket />}></Route>
      <Route path="/cancellations" element={<Cancellations />}></Route>
    </Routes>
  );
}

export default AppRoutes;
