import {
  DeleteFilled,
  EditFilled,
  LaptopOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  Table,
  message,
} from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  useDeleteLocationMutation,
  useGetLocationsQuery,
} from "../../redux/api";
import Search from "antd/es/input/Search";

export default function Location() {
  const { isLoading, data }: any = useGetLocationsQuery("");
  const [searchedText, setSearchedText] = useState("");
  const [deleteLocation] = useDeleteLocationMutation();
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locationNameToDelete, setLocationNameToDelete] = useState("");
  const [locationIdToDelete, setLocationIdToDelete] = useState("");

  let filteredData = data;
  if (filteredData) {
    filteredData = data?.value?.map((item: any) => {
      return { key: item.id, ...item };
    });
  }

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record.name)
          .toLowerCase()
          .includes(String(value).toLowerCase());
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={{ pathname: `/locationDetail/${record.id}` }}>
            <Space>
              <EditFilled />
            </Space>
          </Link>
          <a>
            <Space>
              <DeleteFilled onClick={() => showModal(record)} />
            </Space>
          </a>
        </Space>
      ),
    },
  ];

  const showModal = (record: any) => {
    setLocationNameToDelete(record.name);
    setLocationIdToDelete(record.id);
    setIsModalOpen(true);
  };
  const handleOk = () => {
    deleteLocation(locationIdToDelete).then((result: any) => {
      if (result.error) {
        setIsModalOpen(false);
        message.error(
          `Failed to delete ${locationNameToDelete}. ${result.error.status}`
        );
      } else {
        setIsModalOpen(false);
        message.success(
          `Location ${locationNameToDelete} is deleted successfully.`
        );
      }
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      {contextHolder}
      <Modal
        title="Delete Vehicle"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete"
        okButtonProps={{
          danger: true,
          value: "Delete",
          icon: <DeleteFilled />,
        }}
      >
        <p>
          Are you sure you want to delete location <b>{locationNameToDelete}</b>{" "}
          ?
        </p>
      </Modal>
      <h2>
        Locations
        <Search
          className="search"
          placeholder="Search Location"
          onSearch={(currValue) => {
            setSearchedText(currValue);
          }}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
          enterButton
        />
        <Link to="/locationDetail">
          <Button className="add-button">
            <PlusOutlined />
            Add Location
          </Button>
        </Link>
      </h2>
      <Divider></Divider>
      <Table columns={columns} dataSource={filteredData}></Table>
    </>
  );
}
