import { createReducer, createSlice } from "@reduxjs/toolkit";

export const myreducer = createSlice({
  name: "myreducer",
  initialState: {},
  reducers: {},
});

export const rootReducer = createReducer(
  { isAuthenticated: false },
  {
    login: (state) => {
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
  }
);
