import {
  Button,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Col,
  TimePicker,
  Select,
  SelectProps,
  InputNumber,
  Space,
  message,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs";
import {
  useAddTripMutation,
  useGetLocationsQuery,
  useGetTripsQuery,
  useLazyGetTripsByIdQuery,
  useUpdateTripMutation,
} from "../../redux/api";
import { Trip } from "../../models/Trip";
import { Stoppage } from "../../models/Stoppage";
import { DeleteFilled } from "@ant-design/icons";

export default function TripDetail() {
  const { tripId } = useParams();
  const [getTripById, results] = useLazyGetTripsByIdQuery();
  const [form] = Form.useForm();
  const [updateTrip] = useUpdateTripMutation();
  const [addTrip] = useAddTripMutation();
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const locations: any = useGetLocationsQuery("");
  const locationOptions: SelectProps["options"] = [];
  const [formValues, setFormValues] = useState([
    { id: "", label: "", time: "" },
  ]);

  useEffect(() => {
    if (tripId) {
      getTripById(tripId!, true);
      if (results && results.data?.value) {
        setEditMode(true);
        form.setFieldsValue(results.data.value);
        setFormValues([...results.data.value.stoppage])
      }
    } else {
      setEditMode(false);
    }
  }, [results]);
  type Location = { id: string; name: string };

  const format = "HH:mm";
  locations.data?.value?.forEach((location: any) => {
    locationOptions.push({
      label: location.name,
      value: location.id,
    });
  });

  const onStartTimeChange = (time: Dayjs | null, timeString: string) => {
    setStartTime(timeString);
  };

  const onEndTimeChange = (time: Dayjs | null, timeString: string) => {
    setEndTime(timeString);
  };

  const onStoppageTimeChange = (time: any, index: any) => {
    if (time) {
      const stoppageTime = new Date(time).toLocaleTimeString([], {
        timeStyle: "short",
      });
      let newFormValues: any = [...formValues];
      newFormValues[index].time = stoppageTime;
      setFormValues(newFormValues);
    }
  };

  let handleChange = (i: any, e: any) => {
    let newFormValues = [...formValues];
    newFormValues[i].id = e.value;
    newFormValues[i].label = e.label;
    setFormValues(newFormValues);
  };
  let addFormFields = () => {
    setFormValues([...formValues, { id: "", label: "", time: "" }]);
  };

  let removeFormFields = (i: any) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  let navigate = useNavigate();
  const submitHandler = (values: Trip) => {
    // values.start_time = startTime;
    // values.end_time = endTime;
    values.stoppage = formValues;
    values.child_fare = "";
    if (editMode) {
      values.id = tripId!;
      updateTrip(values).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to update trip ${values.title}.\n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`Trip ${values.title} is updated successfully.`);
          navigate("/trip");
        }
      });
    } else {
      addTrip(values).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to add trip ${values.title}.\n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`Trip ${values.title} is updated successfully.`);
          navigate("/trip");
        }
      });
    }
  };
  return (
    <>
      <h2>Add Trip</h2>
      <Divider></Divider>
      <Form layout="vertical" onFinish={submitHandler} form={form}>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item<Trip>
              name="title"
              rules={[{ required: true, message: "Please input title!" }]}
              className="form-item"
              label="Title"
            >
              <Input placeholder="Title" />
            </Form.Item>
            {/* <Row gutter={16}> */}
            <Col className="gutter-row">
              {formValues.map((element, index) => (
                <div className="stoppage-div" key={index}>
                  <Form.Item<Trip>
                    name="stoppage"
                    className="form-item"
                    label="Stoppage"
                    required
                  >
                    <Select
                      options={locationOptions}
                      value={{...element, value: element.id}}
                      labelInValue
                      onChange={(e) => handleChange(index, e)}
                    ></Select>
                    {element.label ? (
                      <Tag className="hidden" color="green">
                        {element.label}{" "}
                      </Tag>
                    ) : (
                      ""
                    )}
                  </Form.Item>
                  <Form.Item
                    className="form-item"
                    label="Stoppage time"
                    required
                  >
                    <TimePicker
                      style={{ width: "80%" }}
                      use12Hours
                      placeholder="Stoppage time"
                      format="h:mm a"
                      value={ dayjs(element.time || "00:00", 'h:mm')}
                      onChange={(e) => onStoppageTimeChange(e, index)}
                    />
                  </Form.Item>
                  {index ? (
                    <Form.Item className="form-item">
                      <Button
                        type="primary"
                        size="small"
                        htmlType="submit"
                        danger
                        onClick={() => removeFormFields(index)}
                      >
                        <DeleteFilled />
                      </Button>
                    </Form.Item>
                  ) : null}
                  
                </div>
              ))}
              <Form.Item className="form-item">
                    <Button
                      type="primary"
                      htmlType="button"
                      onClick={() => addFormFields()}
                    >
                      Add Stoppages
                    </Button>
                  </Form.Item>
            </Col>

            {/* <Col className="gutter-row" span={6}>
                <Form.Item<Trip>
                  name="child_fare"
                  className="form-item"
                  label="Child Fare"
                >
                  <Input placeholder="Child Fare" />
                </Form.Item> */}
            {/* </Col> */}
            {/* </Row> */}
          </Col>
          <Col className="gutter-row" span={18}>
            <Row gutter={16}>
              <Col className="gutter-row" span={6}>
                <Form.Item<Trip>
                  name="distance"
                  rules={[
                    {
                      required: true,
                      message: "Please input distance in kms!",
                    },
                  ]}
                  className="form-item"
                  label="Distance"
                >
                  <Input placeholder="Distance in Kms" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item<Trip>
                  name="vehicle_id"
                  rules={[
                    { required: true, message: "Please input vehicle id!" },
                  ]}
                  className="form-item"
                  label="Vehicle Id"
                >
                  <Input placeholder="Id no. from vehicle list" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item<Trip>
                  name="approax_time"
                  rules={[
                    {
                      required: true,
                      message: "Please input approaximate time!",
                    },
                  ]}
                  className="form-item"
                  label="Approx time"
                >
                  <Input placeholder="Approaximate time" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={6}>
                <Form.Item<Trip>
                  name="adult_fare"
                  rules={[
                    { required: true, message: "Please input adult fare!" },
                  ]}
                  className="form-item"
                  label="Adult Fare"
                >
                  <Input placeholder="Adult fare" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={16}> */}

            {/* <Col className="gutter-row" span={6}>
            <Form.Item<Trip>
              name="from"
              rules={[
                { required: true, message: "Please input from location!" },
              ]}
              className="form-item"
              label="From"
            >
              <Select options={locationOptions} placeholder="From"></Select>
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={6}>
            <Form.Item<Trip>
              name="to"
              rules={[{ required: true, message: "Please input destination!" }]}
              className="form-item"
              label="To"
            >
              <Select options={locationOptions} placeholder="To"></Select>
            </Form.Item>
          </Col> */}
            {/* <Col className="gutter-row" span={3}>
            <Form.Item<Trip>
              name="start_time"
              rules={[{ required: true, message: "Please input start time!" }]}
              className="form-item"
              label="Start Time"
            >
              <TimePicker
                style={{ width: "80%" }}
                use12Hours
                placeholder="Start time"
                format="h:mm a"
                onChange={onStartTimeChange}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={3}>
            <Form.Item<Trip>
              name="end_time"
              rules={[{ required: true, message: "Please input end time!" }]}
              className="form-item"
              label="End time"
            >
              <TimePicker
                style={{ width: "80%" }}
                use12Hours
                placeholder="End time"
                format="h:mm a"
                onChange={onEndTimeChange}
              />
            </Form.Item>
          </Col> */}
            {/* </Row> */}

            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item<Trip>
                  name="is_active"
                  rules={[
                    {
                      required: true,
                      message: "Please select active or disabled!",
                    },
                  ]}
                  className="form-item"
                  label="Status (only active trip will be shown to users)"
                >
                  <Radio.Group>
                    <Radio value={true}>Active</Radio>
                    <Radio value={false}>Disable</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item>
          <Link to="/trip">
            <Button className="btn-cancel" type="primary">
              Cancel
            </Button>
          </Link>
          {tripId ? (
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  );
}
