import { Divider, Table, message } from "antd";
import { useGetCancellationsQuery } from "../../redux/api";
import Search from "antd/es/input/Search";
import { useState } from "react";

export function Cancellations() {
  const { isLoading, data }: any = useGetCancellationsQuery("");
  const [searchedText, setSearchedText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  let filteredData = data;
  if (filteredData) {
    filteredData = data?.value?.map((item: any) => {
      return { key: item.id, ...item };
    });
  }
  const columns = [
    {
      title: "User Id",
      dataIndex: "user_id",
      key: "id",
    },
    {
      title: "Booking Id",
      dataIndex: "booking_id",
      key: "booking_id",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(record.id)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.cancel_fee)
            .toLowerCase()
            .includes(String(value).toLowerCase()) ||
          String(record.details)
            .toLowerCase()
            .includes(String(value).toLowerCase())
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "cancel_fee",
      key: "cancel_fee",
    },
    {
      title: "Type",
      dataIndex: "payment_type",
      key: "payment_type",
    },
    {
      title: "Payment Details",
      dataIndex: "details",
      key: "details",
    },
    {
      title: "Date",
      dataIndex: "cancel_at",
      key: "cancel_at",
    },
  ];
  return (
    <>
      {contextHolder}
      <h2>
        Cancellations
        <Search
          className="search"
          placeholder="Search Cancellation"
          onSearch={(currValue) => {
            setSearchedText(currValue);
          }}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
          enterButton
        />
      </h2>
      <Divider></Divider>
      <Table columns={columns} dataSource={filteredData}></Table>
    </>
  );
}
