import { Button, Divider, Form, Input, Row, Col, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  useLazyGetUserByIdQuery,
  useUpdateUserMutation,
  useAddUserMutation,
} from "../../redux/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { User } from "../../models/User";
import { useEffect, useState } from "react";

function UserDetail() {
  const [addUser] = useAddUserMutation();
  const { userId } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  let navigate = useNavigate();
  const [updateUser] = useUpdateUserMutation();
  const [form] = Form.useForm();
  const [getUserById, results]: any = useLazyGetUserByIdQuery();
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    if (userId) {
      getUserById(userId!, true);
      if (results && results.data?.value) {
        setEditMode(true);
        form.setFieldsValue(results.data.value);
      }
    } else {
      setEditMode(false);
    }
  }, [results]);

  const submitHandler = (values: User) => {
    if (editMode) {
      const updatedUser: any = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        password: values.password,
        country: values.country,
        id: userId!,
      };
      updateUser(updatedUser).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to update user ${values.name}.\n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`User ${values.name} is updated successfully.`);
          navigate("/users");
        }
      });
    } else {
      const user: any = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        password: values.password,
        country: values.country,
      };
      addUser(user).then((result: any) => {
        if (result.error) {
          message.error(
            `Failed to add user ${values.name}.\n Please contact system administrator if problem persists. `
          );
        } else {
          message.success(`User ${values.name} is added successfully.`);
          navigate("/users");
        }
      });
    }
  };

  return (
    <div>
      {contextHolder}
      <h2>Add User</h2>
      <Divider></Divider>
      <Form layout="vertical" onFinish={submitHandler} form={form}>
        <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <Form.Item<User>
              name="name"
              required
              className="form-item"
              label="Name"
              rules={[{ required: true, message: "Please input full name.!" }]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item<User>
              name="phone"
              className="form-item"
              label="Phone"
              rules={[{ required: true, message: "Please input phone no.!" }]}
            >
              <Input placeholder="Phone number" />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row gutter={16}>
          <Col className="gutter-row" span={8}>
            <Form.Item<User>
              name="nidType"
              className="form-item"
              label="Id Type"
            >
              <Input placeholder="Id/Passport" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item<User>
              name="nidNumber"
              className="form-item"
              label="Identity Number"
            >
              <Input placeholder="Identity Number" />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item<User>
              name="country"
              className="form-item"
              label="Country"
              rules={[{ required: true, message: "Please input country.!" }]}
            >
              <Input placeholder="Country" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={8}>
            <Form.Item<User>
              name="email"
              className="form-item"
              label="Email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input placeholder="Email id" />
            </Form.Item>
          </Col>
          {/* <Col className="gutter-row" span={6}>
            <Form.Item<User> name="zip" className="form-item" label="Zip Code">
              <Input placeholder="Zip Code" />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item<User>
              name="address"
              className="form-item"
              label="Address"
              rules={[{ message: "Please input address.!" }]}
            >
              <TextArea rows={4} placeholder="address" />
            </Form.Item>
          </Col> */}
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item<User>
              name="password"
              className="form-item"
              label="Password"
              rules={[{ required: true, message: "Please input password.!" }]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={6}>
            <Form.Item
              name="confirmPasswword"
              className="form-item"
              label="Re-Type Password"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password placeholder="Re-Type password" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}></Row>
        <Form.Item>
          <Link to="/users">
            <Button className="btn-cancel" type="primary">
              Cancel
            </Button>
          </Link>
          {userId ? (
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
}

export default UserDetail;
